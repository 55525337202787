/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     OUESTADAM CONFIDENTIAL                                  !
  !                                                             !
  !     WWW.OUESTADAM.COM Web App Code                          !
  !     (C) COPYRIGHT OUESTADAM 2024                            !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : wwwInit.js                                         !
  !  Desc. : www.ouestadam.com Front Server initialization      !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 02/05/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- Ouestadam products
*/
import {OeComTrans_c} from './oecommon/oeComTrans'
import {wwwDefDevice, wwwDefModal_e, wwwDefOrientation} from "./wwwDef";
import {wwwConfig} from './wwwConfig'
import {wwwTrans} from "./wwwTrans";

/*=============== Exported functions ==========================*/

/*+-------------------------------------------------------------+
  ! Routine    : wwwInit_f                                      !
  ! Description: Initialize the front server                    !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export async function wwwInit_f(paramCtx) {
    /*
    --- Create Config object
    */
    paramCtx.config_o = wwwConfig;
    /*
    --- Initialize the Refresh functions
    */
    paramCtx.refresh_o = {};
    /*
    --- Current Window information
    */
    paramCtx.window_o = {
        agent: "",
        device: wwwDefDevice.desktop,
        orientation: wwwDefOrientation.landscape,
        width: 0,
        height: 0
    }
    /*
    --- Initialize the current Modal to open
    */
    paramCtx.currentModal = wwwDefModal_e.noModal;
    /*
    --- Update the locale if defined in the navigator
    */
    const locLocale = navigator.language;
    if (locLocale !== undefined) {
        paramCtx.config_o.locale = (locLocale.substring(0, 2).toLowerCase() === 'fr') ? 'fr-FR' : 'en-GB';
    }
    /*
    --- Create Translation object
    */
    paramCtx.trans_o = new OeComTrans_c(paramCtx.config_o.locale, wwwTrans);
}

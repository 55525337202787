/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     OUESTADAM CONFIDENTIAL                                  !
  !                                                             !
  !     WWW.OUESTADAM.COM Web App Code                          !
  !     (C) COPYRIGHT OUESTADAM 2024                            !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : wwwConfig.js                                       !
  !  Desc. : www.ouestadam.com Configuration parameters         !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 14/05/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Definitions ==================================*/
/*
--- SissiF configuration parameters
*/
export const wwwConfig = {
    version: "1.01",
    modification: "14/05/2024",
    locale: "fr-FR",
    colors: {
        backgroundMainGrey: "#6F7170",
        backgroundMainBlack: "#000000",
        backgroundMainWhite: "#ffffff",
        backgroundCoordinateStart: "#77411f",
        backgroundCoordinateEnd: "#d89e5c",
        backgroundIcon: "#f3f2f0",
        foregroundMain: "#ffffff",
        foregroundIcon: "#ffffff",
        foregroundError: "#ff0000",
    },
};
/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     OUESTADAM CONFIDENTIAL                                  !
  !                                                             !
  !     OUESTADAM CONFIDENTIAL                                  !
  !     (C) COPYRIGHT OUESTADAM 2024                            !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : sissifConfig.js                                    !
  !  Desc. : www.ouestadam.com Translation table                !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 02/05/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Definitions ==================================*/
/*
--- wwww translation table
*/
export const wwwTrans = {
    "common": {
        "cancel": {
            en: "Cancel",
            fr: "Annuler"
        }, "return": {
            en: "Return",
            fr: "Retour"
        },
        "cookies": {
            en: "This application doesn't use any Cookie",
            fr: "Cette application n'utilise aucun Cookie"
        }
    },
    "coordinate": {
        "name": {
            en: "Denis ESTEVE",
            fr: "Denis ESTEVE"
        }, "company": {
            en: "OUESTADAM-ESTEVE freelance",
            fr: "Micro-entreprise OUESTADAM-ESTEVE"
        }, "object": {
            en: "Fullstack software developer",
            fr: "Développeur de logiciels fullstack"
        }, "siret": {
            en: "SIRET: 98 52 83 860 00015",
            fr: "SIRET: 98 52 83 860 00015"
        }, "ape": {
            en: "APE: 6201-Z",
            fr: "APE: 6201-Z",
        }, "address1": {
            en: "1400 Chemin de la plus haute Sine",
            fr: "1400 Chemin de la plus haute Sine"
        }, "address2": {
            en: "06140 Vence - France",
            fr: "06140 Vence - France"
        }, "email": {
            en: "denis@ouestadam.com",
            fr: "denis@ouestadam.com"
        }
    },
    "skillDev": {
        "title": {
            en: "Software development",
            fr: "Développement de logiciels"
        },
        "line1": {
            en: "- Web applications (front-end) development with React, Mui, Carbon...",
            fr: "- Développement d'applications Web (front-end) sous React, Mui, Carbon..."
        },
        "line2": {
            en: "- Back-end server development with NodeJs, Java, REST Api...",
            fr: "- Développement de la partie serveur (back-end) sous NodeJs, Java, Api REST..."
        },
        "line3": {
            en: "- Languages: Javascript, Typescript, Java, C, C++, Perl...",
            fr: "- Programmation en langages : Javascript, Typescript, Java, C, C++, Perl..."
        }
    },
    "skillVirtual": {
        "title": {
            en: "Virtual environments",
            fr: "Environnements virtuels"
        },
        "line1": {
            en: "- Docker images outputs.",
            fr: "- Production d'images Docker."
        },
        "line2": {
            en: "- VMWare Virtual machines bring-up.",
            fr: "- Mise en place de machines virtuelles sur VMWare."
        },
        "line3": {
            en: "- Virtual machines on cloud bring-up (OVH Cloud,...).",
            fr: "- Mise en place de machines virtuelles sur Cloud (OVH Cloud,...)."
        }
    },
    "skillInfra": {
        "title": {
            en: "Infrastructures",
            fr: "Infrastructures"
        },
        "line1": {
            en: "- NAS, SAN, Local Network configuration.",
            fr: "- Configuration de NAS, de SAN, et de réseaux locaux."
        },
        "line2": {
            en: "- Operating System installation and maintenance: Linux, Windows, Aix, MacOs.",
            fr: "- Installation et maintenance des OS Linux, Windows, Aix, MacOS."
        },
        "line3": {
            en: "",
            fr: ""
        }
    },
    "skillBuild": {
        "title": {
            en: "Build environment",
            fr: "Environnement de production de logiciels"
        },
        "line1": {
            en: "- Software production environment bring-up.",
            fr: "- Mise en place d'environnements de production de logiciels."
        },
        "line2": {
            en: "- Production tools: Git, GitHub, Apache SVN, Jenkins, Artifactory, Nexus...",
            fr: "- Outils de production: Git, GitHub, Apache SVN, Jenkins, Artifactory, Nexus..."
        },
        "line3": {
            en: "",
            fr: ""
        }
    }
};
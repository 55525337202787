/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     OUESTADAM CONFIDENTIAL                                  !
  !                                                             !
  !     WWW.OUESTADAM.COM Web App Code                          !
  !     (C) COPYRIGHT OUESTADAM 2024                            !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : wwwDef.js                                          !
  !  Desc. : www.ouestadam.com Definitions file                 !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 02/05/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Exported definitions =========================*/
/*
--- Modals list
*/
export const wwwDefModal_e = {
    noModal: 0,
};

/*
--- Window orientation
*/
export const wwwDefOrientation = {
    landscape: 0,
    portrait: 1
}

/*
--- Device type
*/
export const wwwDefDevice = {
    desktop: 0,
    tablet: 1,
    mobile: 2
}

/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     OUESTADAM CONFIDENTIAL                                  !
  !                                                             !
  !     WWW.OUESTADAM.COM Web App Code                          !
  !     (C) COPYRIGHT OUESTADAM 2024                            !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : index.js                                           !
  !  Desc. : www.ouestadam.com start point                      !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 02/05/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- Diginesfront resources
*/
import './index.css';
import {wwwInit_f} from "./wwwInit";
import {wwwMain_f} from "./wwwMain";

/*=============== Global variables =============================*/
/*
---- General context
*/
const wwwCtx = {};

/*=============== Main =========================================*/
/*
--- Start initialization
*/
wwwInit_f(wwwCtx);
/*
--- Start the Front Main page
*/
wwwMain_f(wwwCtx);

